<template>
    <div class="h-full-screen w-full">
        <Teleport to="body">
            <NotificationsHolder></NotificationsHolder>
        </Teleport>
        <PrimaryHeader></PrimaryHeader>
        <slot />
    </div>
</template>
<script setup lang="ts">
import PrimaryHeader from "@/components/PrimaryHeader.vue";
import NotificationsHolder from "@/components/general/NotificationsHolder.vue";
useHead({
    title: "Courserev Chat Booking Agent - Page Not Found",
    meta: [
        {
            name: "description",
            content: "Courserev Chat Booking Agent - Page Not Found",
        },
    ],
    bodyAttrs: {
        class: "h-full",
    },
    htmlAttrs: {
        class: "light h-full",
    },
});
</script>
